import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
// import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import settingsParams from './settingsParams';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSettingsValues } from '@wix/tpa-settings';
import { biDefaults, generateWidgetDefaults } from './bi/consts';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './controller-logic/scheduling-fetcher';
import { ServiceType, TimezoneType } from '@wix/bookings-uou-types';
import { SERVICE_PAGE_NAME } from './constants';
import { initUserMessage } from './controller-logic/init-user-message';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { handleNavigation } from './controller-logic/handle-navigation';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);

  const isMobile = flowAPI.isMobile();
  const isRTL = flowAPI.isRTL();

  return {
    async pageReady() {
      const { controllerConfig } = flowAPI;
      const {
        platformAPIs,
        appParams,
        wixCodeApi,
        setProps,
      } = controllerConfig;
      const publicData = controllerConfig.config.publicData.COMPONENT || {};
      const settings = getSettingsValues(publicData, settingsParams);
      const experiments = await flowAPI.getExperiments();
      const instance = appParams.instance as string;
      const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
        wixCodeApi,
        platformAPIs,
        appParams,
        controllerConfig.compId,
      );

      const isPreview = wixSdkAdapter.isPreviewMode();
      const t = flowAPI.translations.t;
      const loggerDefaults = flowAPI.biLogger?.util.updateDefaults({
        ...biDefaults,
        ...generateWidgetDefaults(appParams, platformAPIs),
      });

      let scheduleViewModel: SchedulingSectionViewModel = {
        status: SchedulingSectionStatus.LOADING,
        isBookable: false,
      };
      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};
      const httpClient = new HttpClient({ isSSR: flowAPI.isSSR() });

      if (!flowAPI.inEditor || isPreview) {
        const servicePageName =
          // @ts-ignore
          wixCodeApi.site.currentPage.url?.substring(1) || SERVICE_PAGE_NAME;
        let servicePageIndex = wixCodeApi.location.path.indexOf(
          servicePageName,
        );
        if (servicePageIndex === -1 && isPreview) {
          servicePageIndex = 1;
        }
        const isServiceSlugExistsInPath =
          servicePageIndex !== -1 &&
          servicePageIndex !== wixCodeApi.location.path.length - 1;
        const serviceSlug = isServiceSlugExistsInPath
          ? wixCodeApi.location.path[servicePageIndex + 1]
          : '';
        const config = await httpClient.request(getConfig, {
          headers: { authorization: instance },
        })(serviceSlug, isPreview);

        navigateToCalendar = () => {
          handleNavigation({
            config,
            wixCodeApi,
            isPreview,
            wixSdkAdapter,
            showUserMessage,
          });
        };

        if (experiments.enabled('specs.bookings.SeoIntegration')) {
          await wixCodeApi.seo.renderSEOTags({
            itemType: 'BOOKINGS_SERVICE',
            itemData: config.SEO.serviceResponse,
            // seoData: userOverrides //JSON string from BO SEO panel
          });
        }
        const isCourse = config.serviceDto.type === ServiceType.COURSE;
        const firstSessionStart =
          config.SEO.serviceResponse?.schedules[0].firstSessionStart;
        const lastSessionEnd =
          config.SEO.serviceResponse?.schedules[0].lastSessionEnd;
        viewModel = servicePageViewModelFactory({
          config,
          t,
        });
        if (!flowAPI.isSSR()) {
          getServiceSchedulingData(config, settings, httpClient, instance)
            .then((schedule) => {
              const changeTimezoneCallback = (timezoneType: TimezoneType) => {
                scheduleViewModel = schedulingSectionViewModelFactory({
                  isBookable: viewModel.body.isBookable,
                  catalogSessionsDto: schedule?.sessions,
                  businessInfo: config?.businessInfo,
                  selectedTimezoneType: timezoneType,
                  isCourse,
                  firstSessionStart,
                  lastSessionEnd,
                  t,
                });
                setProps({
                  scheduleViewModel,
                });
              };
              scheduleViewModel = schedulingSectionViewModelFactory({
                isBookable: viewModel.body.isBookable,
                catalogSessionsDto: schedule?.sessions,
                businessInfo: config?.businessInfo,
                isCourse,
                firstSessionStart,
                lastSessionEnd,
                t,
              });
              setProps({
                scheduleViewModel,
                changeTimezoneCallback,
              });
            })
            .catch((e) => {
              console.log('Getting schedules failed ', e);
              scheduleViewModel = {
                status: SchedulingSectionStatus.FAILED,
                isBookable: false,
              };
              setProps({
                scheduleViewModel,
              });
            });
        }
      } else {
        const isEditorX =
          flowAPI.controllerConfig.config.style.styleParams.booleans.responsive;
        viewModel = dummyViewModelFactory({ t, isEditorX });
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.getSiteLanguage('en'),
        };
        scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: settings.scheduleDays,
        });
      }

      const { userMessage, showUserMessage } = initUserMessage(setProps);

      setProps({
        navigateToCalendar,
        scheduleViewModel,
        viewModel,
        isMobile,
        isRTL,
        userMessage,
        isSSR: flowAPI.isSSR(),
      });
    },
  };
};

export default createController;
